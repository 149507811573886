.DateRangePicker_picker_1 {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
}

.react-date-picker {
  border: none;
}

.react-date-picker__wrapper {
  border: none !important;
  padding: 0px 5px;
}

.react-calendar {
  border-radius: 4px;
  overflow: hidden;
  overflow-x: visible;
  border: none !important;
  background: #2d2e8a !important;
  font-family: "Muli", sans-serif !important;
  color: white;
}

.react-date-picker__inputGroup {
  text-align: right;
  color: #2d2e8a !important;
}

.react-date-picker__inputGroup__input {
  color: #2d2e8a !important;
}

.react-date-picker__calendar-button__icon {
  stroke: #2d2e8a;
}

.react-date-picker__calendar--open {
  bottom: unset !important;
  top: 100% !important;
  left: unset !important;
  right: 0% !important;
}

.react-calendar__tile {
  color: #ababab;
}

.react-calendar__tile:hover {
  color: rgba(255, 255, 255, 0.3) !important;
}

.react-calendar__month-view__days__day {
  color: #d4d5ff !important;
}

.react-calendar__month-view__days__day:hover {
     background: rgba(255, 255, 255, 0.1) !important;
     color: white !important;
  }

.react-calendar__month-view__days__day:disabled {
  color: #3f3f3f !important;
  background: #3b3b3b !important;
}

.react-calendar__tile:disabled {
  color: #180e52;
  background: #00052e !important;
}
.react-calendar__tile--now {
  background: #2d2e8a !important;
  text-decoration: underline dotted #cdcdcd;
  color: #d4d5ff !important;
}

.react-calendar__tile--active {
  /* background: rgba(255, 255, 255, 0.2) !important; */
  background: #3c3ead !important;
  z-index: 2;
  box-shadow: 0px 0px 4px 2px #1b1b1b;
  color: white !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background: #1e1f5f !important;
  color: #d1d1d1 !important;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled {
  background: #1b1b1b !important;
  color: #2e2e2e !important;
}

.react-calendar__navigation__label {
  color: white !important;
  background: #2d2e8a !important;
  font-family: "Muli", sans-serif !important;
}

.react-calendar__navigation__arrow {
  background: #1e1f5f !important;
  color: white !important;
}

.react-calendar__navigation__arrow:disabled {
  background: #1b1b1b !important;
  color: white !important;
}
