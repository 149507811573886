html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background1 {
  background-image: linear-gradient(
    to top,
    #640a0a 0%,
    #3f0728 50%,
    #250a38 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  z-index: -1;
}

.background2 {
  background-color: #220f2f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%2300bcff' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

#root {
  position: fixed;
  width: 100%;
  z-index: 1;
  height: 100%;
  justify-content: space-between;
  margin: 0;
  overflow-x: hidden;
  flex-flow: row wrap;
}

* {
  -webkit-tap-highlight-color: transparent;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #444444;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

.scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*:focus {
  outline: none;
}

::selection {
  background: rgba(130, 0, 100, 0.2);
}
::-moz-selection {
  background: rgba(130, 0, 100, 0.2);
}


input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fcfcfc inset !important;
  font-family: "'Muli', sans-serif";
}




input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fafafa inset !important;
}



